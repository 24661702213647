import './SettingsTemplatesTable.less'

import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { Feature, SorterOrder, Template } from '../../../../requests'
import { TableRecord } from '../../../../utils'
import { DatacPaginationItem, DatacTable } from '../../../common'
import { getColumns } from './SettingsTemplatesTableConfig'

interface Props {
  onDelete?: (template: Template) => void
  onEdit?: (template: Template) => void
  onExport?: (template: Template) => void
  currentPage: number
  onPageChange: (page: number) => void
  totalCount: number
  disabled?: boolean
  pageSize: number
  templates: Template[]
  onSorterChange: (tableSorter: { field: keyof Template; order: SorterOrder }) => void
  feature: Feature
}

export const SettingsTemplatesTable: React.FC<Props> = ({
  onDelete,
  onEdit,
  onExport,
  currentPage,
  onPageChange,
  totalCount,
  disabled = false,
  pageSize,
  templates = [],
  onSorterChange,
  feature
}) => {
  const [tableRecords, setTableRecords] = useState<TableRecord<Template>[]>([])
  const intl = useScopedIntl('')
  const intlTemplatesTable = useScopedIntl('settings.templates.table')

  useEffect(() => {
    setTableRecords(templates.map(createRecord))
  }, [templates])

  const createRecord = (template: Template) => ({
    ...template,
    key: `${template.type}-${template.id}`
  })

  const columns = getColumns({
    columnNames: {
      title: intlTemplatesTable('title'),
      description: intlTemplatesTable('description'),
      tags: intlTemplatesTable('tags'),
      type: intlTemplatesTable('type'),
      createdDate: intlTemplatesTable('created_date'),
      ownerName: intlTemplatesTable('owner_name'),
      actions: intl('common.actions')
    },
    onDelete,
    onEdit,
    onExport,
    feature
  })

  return (
    <DatacTable
      className="settings-templates-table"
      dataSource={tableRecords}
      columns={columns}
      rowSelection={undefined}
      scroll={{ x: 1200 }}
      pagination={{
        current: currentPage,
        position: ['bottomCenter'],
        itemRender: DatacPaginationItem,
        onChange: onPageChange,
        total: totalCount,
        showSizeChanger: false,
        disabled,
        pageSize
      }}
      onChange={(pagination, filters, sorter) =>
        onSorterChange(sorter as { field: keyof Template; order: SorterOrder })
      }
    />
  )
}
