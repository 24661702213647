import './SettingsTemplatesContent.less'

import { Button, Tabs } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AclAction,
  AclFeature,
  Feature,
  SorterOrder,
  Template,
  TemplateSorter,
  deleteTemplate,
  exportTemplate,
  fetchTemplates
} from '../../../requests'
import { UserContext } from '../../auth'
import { DatacIcon, DatacMessage, DatacModal, DatacTitle } from '../../common'
import { SettingsLayout } from '../SettingsLayout'
import { SettingsTemplatesImportModal } from './SettingsTemplatesImportModal'
import { SettingsTemplatesTable } from './SettingsTemplatesTable'
import { TemplateTags } from './TemplateTags'

const defaultSorter: TemplateSorter = { field: 'id', order: SorterOrder.Ascend }

const pageSize = 20
export const SettingsTemplatesContent: React.FC = () => {
  const intl = useScopedIntl('')
  const intlSettingsTemplates = useScopedIntl('settings.templates')
  const [templates, setTemplates] = useState<Template[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [allTemplatesCount, setAllTemplatesCount] = useState(0)
  const [isFetchingTemplates, setIsFetchingTemplates] = useState(false)
  const [sorter, setSorter] = useState<TemplateSorter>(null)
  const [templateToDelete, setTemplateToDelete] = useState<Template>(null)
  const [isDeletingTemplate, setIsDeletingTemplate] = useState(false)
  const [selectedFeature, setSelectedFeature] = useState<Feature>(Feature.Ecrf)
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const { user } = useContext(UserContext)
  const [isImportModalOpened, setIsImportModalOpened] = useState(false)

  useEffect(() => {
    fetchTemplatesPage(selectedFeature, 0, sorter)
  }, [])

  const onPageChange = (page: number) => {
    fetchTemplatesPage(selectedFeature, page - 1, sorter)
  }

  const refreshTemplates = () => {
    fetchTemplatesPage(selectedFeature, currentPage - 1, sorter)
  }

  const onSorterChange = (tableSorter: { field: keyof Template; order: SorterOrder }) => {
    const sorterChanged =
      (!sorter && tableSorter.order) ||
      (sorter && (tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order))
    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchTemplatesPage(selectedFeature, currentPage - 1, newSorter)
      setSorter(newSorter)
    }
  }

  const fetchTemplatesPage = (target: Feature, page: number, sorter: TemplateSorter, tags = selectedTags) => {
    setIsFetchingTemplates(true)
    return fetchTemplates(
      { target, options: { limit: pageSize, offset: page * pageSize, sorter: sorter || defaultSorter, tags } },
      {
        onSuccess: (templates, allTemplatesCount) => {
          setTemplates(templates)
          setAllTemplatesCount(allTemplatesCount)
          setIsFetchingTemplates(false)
          setCurrentPage(page + 1)
        },
        onRequestError: code => {
          setIsFetchingTemplates(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onAskForDeleteTemplateConfirmation = (template: Template) => {
    setTemplateToDelete(template)
  }

  const onDeleteTemplate = () => {
    setIsDeletingTemplate(true)
    deleteTemplate(
      {
        templateId: templateToDelete.id
      },
      {
        onSuccess: () => {
          refreshTemplates()
          setTemplateToDelete(null)
          setIsDeletingTemplate(false)
        },
        onRequestError: () => {
          DatacMessage.error(intlSettingsTemplates('delete_error.title'), intlSettingsTemplates('delete_error.message'))
          setIsDeletingTemplate(false)
        }
      }
    )
  }

  const onModeChange = (newFeature: Feature) => {
    if (newFeature !== selectedFeature) {
      setSelectedFeature(newFeature)
      fetchTemplatesPage(newFeature, 0, sorter)
    }
  }

  const onTagsChange = (tags: string[]) => {
    setSelectedTags(tags)
    fetchTemplatesPage(selectedFeature, 0, sorter, tags)
  }

  const onExportTemplate = (template: Template) => {
    exportTemplate(
      {
        templateId: template.id
      },
      {
        onRequestError: () => {
          DatacMessage.error(intlSettingsTemplates('export_error.title'), intlSettingsTemplates('export_error.message'))
        }
      }
    )
  }

  const onImportTemplatesModalClose = () => {
    refreshTemplates()
    setIsImportModalOpened(false)
  }

  return (
    <SettingsLayout>
      <div className="settings-templates-content">
        <div className="settings-templates-content__header">
          <DatacTitle type="h2">{intlSettingsTemplates('title')}</DatacTitle>
          <Button
            className="settings-templates-content__header-button"
            type="primary"
            size="large"
            onClick={() => setIsImportModalOpened(true)}
          >
            <DatacIcon
              type="white"
              name="uploadCloud"
              size="big"
              className="settings-templates-content__header-button-icon"
            />
            {intlSettingsTemplates('import')}
          </Button>
        </div>

        <div className="settings-templates-content__tabs">
          <Tabs onChange={newMode => onModeChange(newMode as Feature)}>
            <Tabs.TabPane key={Feature.Ecrf} tab={intlSettingsTemplates('ecrf')} />
            {user.isEproEnabled && <Tabs.TabPane key={Feature.Epro} tab={intlSettingsTemplates('epro')} />}
            {user.isEconsentEnabled && <Tabs.TabPane key={Feature.Econsent} tab={intlSettingsTemplates('econsent')} />}
            {user.canAccess(AclFeature.Recruitment) && user.isRecruitmentEnabled && (
              <Tabs.TabPane key={Feature.Recruitment} tab={intlSettingsTemplates('recruitment')} />
            )}
            {user.canAccess(AclFeature.SideBySide) && user.isSideBySideEnabled && (
              <Tabs.TabPane key={Feature.SideBySide} tab={intlSettingsTemplates('side_by_side')} />
            )}
          </Tabs>
        </div>

        <TemplateTags onSubmit={onTagsChange} selectedTags={selectedTags} />

        <SettingsTemplatesTable
          templates={templates}
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalCount={allTemplatesCount}
          pageSize={pageSize}
          disabled={isFetchingTemplates}
          onSorterChange={onSorterChange}
          onDelete={user.canDo(AclFeature.Templates)(AclAction.Delete) && onAskForDeleteTemplateConfirmation}
          onExport={onExportTemplate}
          feature={selectedFeature}
        />
      </div>

      <DatacModal
        title={intlSettingsTemplates('delete_confirmation.title')}
        isOpened={!!templateToDelete}
        onClose={() => setTemplateToDelete(null)}
        onSubmit={onDeleteTemplate}
        submitLabel={intlSettingsTemplates('delete_confirmation.submit')}
        loading={isDeletingTemplate}
      >
        <div className="settings-templates-content__confirmation-modal">
          {intlSettingsTemplates('delete_confirmation.message')}
        </div>
      </DatacModal>

      <SettingsTemplatesImportModal isOpened={isImportModalOpened} onClose={onImportTemplatesModalClose} />
    </SettingsLayout>
  )
}
